import type {
  GID,
  UnlikelyCart,
  UnlikelyCollection,
  UnlikelyPriceRange,
  UnlikelyVariant,
} from "@unlikelystudio/commerce-connector"

import type { Nullish, ObjectValues } from "~/@types/generics"
import { getProductCustomAttributesValue } from "~/lib/shopify/constants"
import type { TVariant } from "~/lib/shopify/serializers/serialize-variants"
import { getReferenceIdFromGID } from "~/lib/shopify/utils/id"
import { handleZodSafeParse } from "~/lib/zod/utils/handle-zod-safe-parse"
import { linkSchema } from "~/components/ui/Link/_data/schema"
import serializeLink from "~/components/ui/Link/_data/serializer"

export type PageName =
  | "Customer Info Page"
  | "Customer Adresses Page"
  | "Customer Orders Page"
  | "Enabled Account Page"
  | "Forgot Password Page"
  | "Login Page"
  | "Register Page"
  | "Reset Password Page"
  | "Collection Page"
  | "Le Journal Page"
  | "Legals Page"
  | "Universal Page"
  | "Product Page"
  | "Search Page"
  | "Home Page"

export type ViewedItemListPayloadListName =
  | "Collection Page"
  | "Collection Material Page"
  | "Cross Sell"
  | "Slice: Product Slider"
  | "Slice: Three Product Cards"
  | "Slice: Four Product Cards"
  | "Slice: Edito Two Product Card"
  | "Slice: Product Card Image Diptych"
  | "Shop The Look"

export type ViewedItemListPayload = {
  item_id: Nullish<string>
  item_name: Nullish<string>
  item_category: Nullish<string>
  item_list_id: Nullish<string>
  item_list_name: ViewedItemListPayloadListName
  item_variant: Nullish<string>
  item_sku: Nullish<string>
  item_url: Nullish<string>
  item_image_url: Nullish<string>
  index: number
  price: Nullish<number>
  quantity: 1
}

export type ViewItemListProductPayload = {
  id: Nullish<GID>
  handle?: Nullish<string>
  title: Nullish<string>
  variants: TVariant[] | UnlikelyVariant[]
  priceRange: Nullish<UnlikelyPriceRange>
}

export function serializeViewedItemListPayload(
  product: ViewItemListProductPayload,
  collection: Nullish<UnlikelyCollection>,
  trackingData: Pick<ViewedItemListPayload, "item_list_name" | "index">,
  locale: Nullish<string>
): ViewedItemListPayload {
  const currentVariant = product?.variants?.[0] ?? null
  const parsedLink = handleZodSafeParse(linkSchema, product, {
    errorContext: `serializeProductCard: ${product?.handle}`,
    locale,
  })
  const link = parsedLink ? serializeLink(parsedLink, locale, false) : null

  const imageItemURL =
    currentVariant?.image && Array.isArray(currentVariant?.image)
      ? currentVariant?.image[0]?.src
      : currentVariant?.image && "url" in currentVariant?.image
      ? currentVariant?.image?.url
      : "" ?? ""

  return {
    item_variant: currentVariant?.title ?? "",
    item_image_url: imageItemURL,
    item_url: link?.href ?? "",
    item_sku: currentVariant?.sku ?? "",
    item_id: serializeItemIdFromProductInfos(product?.id, product?.variants?.[0]?.id),
    item_name: product.title,
    item_category: collection?.title,
    price: product?.priceRange?.minVariantPrice?.amount,
    item_list_id: collection?.handle,
    quantity: 1,
    ...trackingData,
  }
}

export type ViewedItemPayload = {
  item_id: Nullish<string>
  item_name: Nullish<string>
  item_category: Nullish<string>
  item_variant: Nullish<string>
  item_sku: Nullish<string>
  item_image_url: Nullish<string>
  item_url: Nullish<string>
  price: Nullish<number>
  compare_at_price: Nullish<number>
  currency: Nullish<string>
  quantity: 1
}

export type CartLineItemPayload = {
  item_variant: Nullish<string>
  item_id: Nullish<string>
  item_name: Nullish<string>
  item_sku: Nullish<string>
  item_url: Nullish<string>
  item_image_url: Nullish<string>
  price: Nullish<number>
  compare_at_price: Nullish<number>
  currency: Nullish<string>
  quantity: Nullish<number>
  item_category: Nullish<string>
  index: number
  from_pop_in_cross_sell?: Nullish<boolean>
  from_pre_cta_cross_sell?: Nullish<boolean>
}

export function serializeItemIdFromProductInfos(productId: Nullish<GID>, variantId: Nullish<GID>) {
  const referenceVariantId = getReferenceIdFromGID(variantId)
  return referenceVariantId ? referenceVariantId.toString() : ""
}

export function serializeCartPayload(cart: UnlikelyCart): { value: Nullish<number>; items: CartLineItemPayload[] } {
  return {
    value: cart?.cost?.totalAmount?.amount ?? 0,
    items: cart?.lines?.map((lineItem, index) => {
      const productId = (getProductCustomAttributesValue(lineItem?.attributes, "PRODUCT_ID") as GID) ?? null

      return {
        item_variant: lineItem?.merchandise?.title ?? "",
        item_id: lineItem?.attributes ? serializeItemIdFromProductInfos(productId, lineItem?.merchandise?.id) : "",
        item_name: lineItem?.merchandise?.product?.title ?? "",
        item_sku: lineItem?.merchandise?.sku ?? "",
        item_url: getProductCustomAttributesValue(lineItem.attributes, "PRODUCT_URL") ?? "",
        item_image_url: lineItem?.merchandise?.image?.url ?? "",
        price: lineItem?.merchandise?.price?.amount ?? null,
        compare_at_price: lineItem?.merchandise?.compareAtPrice?.amount ?? null,
        currency: lineItem?.merchandise?.price?.currencyCode ?? "",
        quantity: lineItem?.quantity ?? 0,
        item_category: getProductCustomAttributesValue(lineItem.attributes, "COLLECTION") ?? "",
        index,
      }
    }) satisfies CartLineItemPayload[],
  }
}

type PagePayload = {
  page_title: PageName
  page_location: string
  page_path: string
  locale: string
}

type UserPayload = {
  user_id: string
}

export type TrackingEvents = {
  view_item_list: { ecommerce: { items: ViewedItemListPayload[] } }
  select_item_list: { ecommerce: { items: [ViewedItemListPayload] } }
  view_item: { ecommerce: { items: [ViewedItemPayload] } }
  add_to_cart: {
    ecommerce: {
      value: Nullish<number>
      cart_url: string
      items: CartLineItemPayload[]
      items_in_cart: CartLineItemPayload[]
    }
  }
  remove_from_cart: { ecommerce: { value: Nullish<number>; items: CartLineItemPayload[] } }
  view_cart: { ecommerce: { value: Nullish<number>; items: CartLineItemPayload[] } }
  page_view: PagePayload
  login: UserPayload
  register: UserPayload
  newsletter: UserPayload
}

export type TrackingEventKey = keyof TrackingEvents
export type EventPayload = ObjectValues<TrackingEvents>
